<template>
  <div :class="$style['pt-bill-receive']">
    <validate-form tag="div" ref="form">
      <pt-form-item
        v-if="!isNormal"
        class="form-item"
        tag="div"
        name="异常原因"
        rules="required"
        ref="reason"
      >
        <div :class="$style['form-item-box']">
          <span>异常原因：</span>
          <wt-input v-model="formValue.reason" placeholder="请填写原因">
          </wt-input>
        </div>
      </pt-form-item>
      <pt-form-item
        class="form-item"
        tag="div"
        name="快递单号"
        ref="expressIds"
      >
        <div :class="$style['form-item-box']">
          <span>快递单号：</span>
          <pt-array-input v-model="formValue.expressIds" placeholder="请填写快递单号">
          </pt-array-input>
          <!-- <cube-textarea v-model="formValue.reason" autofocus placeholder="请填写您的驳回原因"></cube-textarea> -->
        </div>
      </pt-form-item>
    </validate-form>
    <div :class="$style['btn-box']">
      <wt-button :class="$style['submit-btn']" @click="submit" primary>确认提交</wt-button>
    </div>
    
  </div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-bill-receive'
import {nextTrust} from '../status'

export default {
  name: 'pt-bill-receive',
  computed: {
    classes () {
      return `${prefixCls}`
    },
    isNormal(){
      return this.$route.params.isNormal === 'true'
    }
  },
  data () {
    return {
      formValue: {},
      // authorizationCode:null
    };
  },
  methods:{
    submit () {
      this.$refs.form.validate("formValue").then((valid) => {
        console.log('---valid',valid)
        if(!valid){
          return
        }
        let data = {
          action: 'BILL_RECEIVE',
          billId:this.$route.params.id,
          billStatusDetail:{
            action: 'BILL_RECEIVE',
            billStatusResult: this.isNormal ? 'QUALIFIED' : 'NOT_QUALIFIED',
            reason: !this.isNormal ? this.formValue.reason : null,
            expressIds:this.formValue.expressIds ? this.formValue.expressIds.map(e=>e.value) : null
          }
        }
        this.$loading()
        nextTrust.call(this,data).then(response => {
          this.$router.go(-1)
        }).catch(err => {
          this.$toast.error(err.message || err)
        }).finally(()=>{
          this.$loading.hide()
        })
      });
      
    
    },
  }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-bill-receive{
  > div:first-child{
    background-color: #fff;
    padding:@page-padding 25px;
    >div{
      &:last-child{
        .form-item-box{
          border-bottom: none;
        }
      }
    }
  }
  .btn-box{
    padding: 20px @page-padding;
    :global{
      .cube-btn{
        width: 100%;
      }
    }
  }
}
.form-item-box{
  display: flex;
  align-items: baseline;
  > span{
    white-space: nowrap;
  }
  :global{
    .wt-input{
      flex: 1;
    }
  }
}
</style>
