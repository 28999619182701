<template>
  <pt-bill-receive class="pt-bill-receive__page"></pt-bill-receive>
</template>

<script>
import PtBillReceive from 'cps/trust-order/pt-bill-receive/withData'
export default {
  name: 'pt-bill-receive__view',
  components: {
    'pt-bill-receive': PtBillReceive
  }
}
</script>
