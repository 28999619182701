<template>
  <pt-bill-receive class="pt-bill-receive__withData"></pt-bill-receive>
</template>

<script>
import PtBillReceive from './index'
export default {
  name: 'pt-bill-receive__withData',
  components: {
    'pt-bill-receive': PtBillReceive
  }
}
</script>
